'use client';

import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { CardAttributes } from '@/features/carousel/model';
import { $gameFeed, TGameData } from '@/root/app/[locale]/games/[slug]/model';
import React, { useContext } from 'react';
import { UIKIT_CONSTS } from '../UiKit/UiKitConnector';
import { UiKitRenderStatic } from '../UiKit/UiKitRender';
import { LocalizedLink } from '@/components/Link';
import { LocalStorageService } from '@/libs/utils/local-storage';
import { useDeviceDetector } from '@/libs/utils/userAgentContext';
import { renderMarkup } from '@/uikit/components/ark-ui-block-card-standard/_index';
import { AnalyticsContext } from '@/components/Layout';
import { ETabIndexesOrderingLevels } from '@/types/types';
import { Badge } from '@/libs/api/arena-data';
import { getValidBadgesBySlug } from '@/libs/utils/utils';
import { useUnit } from 'effector-react';
interface RecentlyPlayedProps {
  arenaData: any;
  arenaBadges?: Badge[];
}
const RecentlyPlayed = ({
  arenaData,
  arenaBadges
}: RecentlyPlayedProps) => {
  const {
    isMobile
  } = useDeviceDetector();
  const recentlyPlayedGames = LocalStorageService.getItem('recentlyPlayed');
  const recentlyPlayedGamesList = recentlyPlayedGames ? JSON.parse(recentlyPlayedGames) : [];
  const lastRecentlyPlayedGame = recentlyPlayedGamesList[0];
  const {
    AITracks
  } = useContext(AnalyticsContext);
  const gameFeedData = useUnit($gameFeed);

  //validate object
  if (!lastRecentlyPlayedGame) {
    return null;
  }
  const recentlyPlayedGameAliasOrSlug = lastRecentlyPlayedGame?.meta?.alias || lastRecentlyPlayedGame?.slug;
  const recentlyPlayedCardAttributesOverride = arenaData?.layout?.styleOverride?.standardCard || null;
  const recentlyPlayedCardTitle: string = 'Recently played';
  const recentlyPlayedCardAttributes: CardAttributes = {
    onMobile: isMobile(),
    size: 'medium',
    action_bar: 'true',
    thumbnail_mode: 'false',
    title: recentlyPlayedCardTitle,
    background_color: 'transparent',
    background_image_desktop: lastRecentlyPlayedGame?.meta?.thumbs?.graphic_288x192 || '',
    background_image_mobile: lastRecentlyPlayedGame?.meta?.thumbs?.graphic_288x192 || '',
    badge: getValidBadgesBySlug(gameFeedData, lastRecentlyPlayedGame?.slug || '', arenaBadges ?? []),
    link_label: '',
    game_category: lastRecentlyPlayedGame?.meta?.categories?.[0] || '',
    game_name: lastRecentlyPlayedGame?.name || '',
    game_description: lastRecentlyPlayedGame?.meta?.description || '',
    game_slug: lastRecentlyPlayedGame?.slug || '',
    game_alias: lastRecentlyPlayedGame?.meta?.alias || '',
    description: '',
    shortDescription: lastRecentlyPlayedGame?.meta?.details || '',
    override_color_title: recentlyPlayedCardAttributesOverride?.title || '',
    override_hover_block_toggled: recentlyPlayedCardAttributesOverride?.hoverBlock?.type,
    override_hover_block_color_bcg: recentlyPlayedCardAttributesOverride?.hoverBlock?.background,
    override_hover_block_color_text: recentlyPlayedCardAttributesOverride?.hoverBlock?.color,
    override_hover_block_button_text: recentlyPlayedCardAttributesOverride?.hoverBlock?.buttonText || 'Play',
    override_hover_block_button_text_color: recentlyPlayedCardAttributesOverride?.hoverBlock?.buttonTextColor,
    override_hover_block_button_background_color: recentlyPlayedCardAttributesOverride?.hoverBlock?.buttonBackground,
    override_hover_block_button_background_color_end: arenaData?.layout?.styleOverride?.standardCard?.hoverBlock?.buttonBackground_end,
    override_hover_block_button_border_color: recentlyPlayedCardAttributesOverride?.hoverBlock?.buttonBorderColor,
    override_hover_block_button_should_use_border: recentlyPlayedCardAttributesOverride?.hoverBlock?.should_button_use_border
  };
  const recentlyPlayedHandleClick = () => {
    AITracks.genericTrack({
      eventName: 'gameCard',
      isNonInteraction: false,
      eventAction: AnalyticsEventAction.CLICK,
      customDimensions: {
        cardType: 'StandardCard',
        cardTitle: recentlyPlayedCardTitle,
        cardImageURL: lastRecentlyPlayedGame?.meta?.thumbs?.graphic_288x192 || ''
        // badge_text: !isMinimalistic ? badgeText : null,
      }
    });
  };
  const labelTitle = lastRecentlyPlayedGame ? `Play ${lastRecentlyPlayedGame?.name}` : 'Clickable card';
  return <LocalizedLink key={lastRecentlyPlayedGame.id} href={`/games/${recentlyPlayedGameAliasOrSlug}`} className={UIKIT_CONSTS.nextjsLinkWrapperClassname + ' promo-card_recently_link'}
  // target={card.link?.target ?? '_self'}
  onClick={recentlyPlayedHandleClick} aria-label={labelTitle} tabIndex={ETabIndexesOrderingLevels.AUTO} prefetch='default' data-sentry-element="LocalizedLink" data-sentry-component="RecentlyPlayed" data-sentry-source-file="index.tsx">
			<UiKitRenderStatic innerHTML={renderMarkup(recentlyPlayedCardAttributes)} tagName={'ark-ui-block-card-standard'} data-sentry-element="UiKitRenderStatic" data-sentry-source-file="index.tsx" />
		</LocalizedLink>;
};
export default RecentlyPlayed;